<template>
    <Header/>
    <Premanentheader/>
    <Permanentservice/>
    <PermanentSection/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Permanentservice from '@/components/Permanentservice.vue'
import PermanentSection from '@/components/PermanentSection.vue'
import Premanentheader from '@/components/Premanentheader.vue'

export default {
  components: {
     Header,
    Footer,
    Permanentservice,
    PermanentSection,
    Premanentheader
  }
}
</script>