<template>
   <div class="container-fluid backimage" >
    <div class="row">
            <div class="col-12 text-center mobileapphead">
              <Carousel :autoplay="3000" :transition="1000" :items-to-show="1" :itemsToScroll="1" :wrap-around="true">
                <Slide v-for="slide in slider" :key="slide.id">
                  <div class="carousel__item">
                    <div class="carousel-container">
                      <Image :fpath="slide.path"/>
                       </div>
                  </div>
                </Slide>
                <template #addons>
                <Navigation class="appnext"/>
                  <Pagination />
                </template>
              </Carousel>
          </div>
     </div>
 </div>
</template>

<script>

import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import Image from '@/components/Image.vue'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Image
  },
  data() {
   return { slider: [{ id: 1, path: 'App/PERMANENT-STAFFING.png', },{ id: 1, path: 'App/EMPLOYER-BENEFITS.png', },{ id: 1, path: 'App/PERMANENT-EMPLOYEE.png', }] }
   },
   settings: {
      snapAlign: 'center',
    },
});
</script>
<style >
@media only screen and (min-width: 991px) and (max-width: 2570px) {
.mobileapphead{
 padding:90px 0px 0px 0px
}
}
@media only screen and (min-width: 0px) and (max-width: 990px) {
.mobileapphead{
 padding:120px 0px 0px 0px
}
}
.backimage {
    background-image: url(../assets/images/App/Background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.carousel__pagination-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.carousel__pagination-button--active {
    background-color: #72287a;
}
  .carousel__item {
      min-height: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  @media only screen and (min-width: 0px) and (max-width: 990px) {
button.carousel__prev.appnext {
    left: 0px !important;
    opacity: 40%;
}
button.carousel__next.appnext{
  opacity: 40%;
}
button.carousel__prev.appnext:hover {
    opacity: 100%;
}
button.carousel__next.appnext:hover{
  opacity: 100%;
}
}
  .carousel-container>img{
    width:100%;
  }
  .carousel__prev:hover{
      background-color:#ed1d8d;
  }
  .carousel__next:hover{
      background-color:#ed1d8d;
  }
  button.carousel__prev.appnext {
    box-sizing: content-box;
    top: 55%;
    background-color: #72287a;
    left: 20px;
    border: 3px solid;
    transform: translate(50%, -50%);
}
 button.carousel__next.appnext {
      box-sizing: content-box;
      background-color: #72287a;
      top: 55%;
      right: 40px !important;
      border: 3px solid;
      transform: translate(50%, -50%);
  }
  .carousel {
      width: 100%;
  }

</style>
